// LandingPage.js
import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser'; // Import this to safely parse HTML content

// import './custom.css'
export const LandingPageStatic = () => {
    return (<>
        <style>{styles}</style>
  <div className="page-dark" translate="no">
  
  
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css"/>
  
  
  <link rel="stylesheet" href="/static/custom.css"/>
  
  
  <script>
  window.console = window.console || function(t) {};
  </script> 
    <div className="support">Check this out in Chrome 115+ / Chrome Canary</div>
  
  
  
  <nav>
  <div className="nav__content">
  <img src="/static/logo-original-500-cropped.png" alt="Pangea Social" />
  <a href="https://www.pangeasocial.com/"  rel="noopener" target="_blank">Learn More</a>
  </div>
  </nav>
  <section>
    {/* <div className="section__background" style="background-image: url('/static/space/sun-behind-the-earth-in-space-planet-earth-wallpaper-123bdaa5722eb9c27d7658c691566d9b.jpg');"></div> */}
    <div className="section__background" style={{ backgroundImage: "url('/static/space/sun-behind-the-earth-in-space-planet-earth-wallpaper-123bdaa5722eb9c27d7658c691566d9b.jpg')" }}></div>
  <div className="section__content">
  <h1>
  <span>All-new</span>
  <span>Pangea Social</span>
  </h1>
  </div>
  </section>
  <section>
  
  <video src="/static/videos/seoul-21116.mp4" autoPlay="" playsInline="" muted="" loop=""></video>
 
  <div className="cta">
  <div className="cta__content">
  <a href="https://www.pangeasocial.com/" target="_blank" rel="noopener noreferrer">
  <span>Learn More</span> 
  <span>
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">  <path fill-rule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clip-rule="evenodd"></path></svg> 
  </span>
  </a>
  </div>
  </div>
  <div className="section__content">
  
   
  
  <p>
  A <span>Safer Social Media</span> platform powered by <span>Socratic Dialogue AI</span> and <span>micro payment rewards</span>.
  </p>
  </div>
  </section>
  <section>
  </section>
  <section>
  <video src="/static/videos/Gxt0pt4FpYo.mp4" autoPlay="" playsInline="" muted="" loop=""></video>
  
  
  
  <div className="cta " style={{ display: 'none' }}>
    <div className="cta__content">
      <a id="button1" href="#" target="_blank" rel="noopener noreferrer">
        <span>Follow Us</span>
        <span>
        
    <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">  <title>X</title><path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"></path></svg> 
        </span>
      </a>
    </div>
  </div>
  <div className="section__content">
    <p>
       Get <span>Ad-Free</span> AI powered insights &amp; metrics for your content and comments using our <span>AI enhanced writing editor &amp; social media platform.</span> Generate a true ROI for your time, higher quality content, and higher satisfaction <span>for you and your community. </span>
    </p>
  </div>
  
  </section> 
  <section>
    <video src="/static/videos/183147-low.mp4" autoPlay="" playsInline="" muted="" loop=""></video>
    <div className="cta">
      <div className="cta__content">
        <a id="button2" href="#" target="_blank" rel="noopener noreferrer">
        <span>Follow Us</span>
          <span>
            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>X</title><path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"></path></svg>
          </span>
        </a>
      </div>
    </div>
    <div className="section__content">
      <h2><span>Become a</span><span>Pangea Social Founding Member</span></h2>
    </div>
  
  <div className="cta">
    <div className="cta__content">
    <a id="button3" href="https://pangeasocial.com/" target="_blank" rel="noopener noreferrer">
    <span>Learn More</span> 
    <span>
      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">  <path fill-rule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clip-rule="evenodd"></path></svg> 
    </span>
    </a>
    </div>
    </div>
  </section>
  <section> 
    
    <h2>This is just 
  <br/>the beginning...</h2>
    <div className="cta">
    <div className="cta__content">
    <a id="button4" href="" target="_blank" rel="noopener noreferrer">
    <span>Learn More</span> 
    <span>
      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">  <path fill-rule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clip-rule="evenodd"></path></svg> 
    </span>
    </a>
    </div>
    </div>
  </section> 
  <script src="/static/script.js"> 
    </script>
  
  
  
  </div></>);
}


// import './custom.css'
export const LandingPageStaticExample = () => {
    return (<>
        <style>{styles}</style>
  <div className="page-dark" translate="no">
  
  
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css"/>
  
  
  <link rel="stylesheet" href="/static/custom.css"/>
  
  
  <script>
  window.console = window.console || function(t) {};
  </script> 
    <div className="support">Check this out in Chrome 115+ / Chrome Canary</div>
  
  
  
  <nav>
  <div className="nav__content">
  <img src="/static/logo-original-500-cropped.png" alt="Pangea Social" />
  <a href="https://www.pangeasocial.com/" target="_blank">Learn More</a>
  </div>
  </nav>
  <section>
    {/* <div className="section__background" style="background-image: url('/static/space/sun-behind-the-earth-in-space-planet-earth-wallpaper-123bdaa5722eb9c27d7658c691566d9b.jpg');"></div> */}
    <div className="section__background" style={{ backgroundImage: "url('/static/space/sun-behind-the-earth-in-space-planet-earth-wallpaper-123bdaa5722eb9c27d7658c691566d9b.jpg')" }}></div>
  <div className="section__content">
  <h1>
  <span>All-new</span>
  <span>Pangea Social</span>
  </h1>
  </div>
  </section>
  <section>
  
  <video src="/static/videos/seoul-21116.mp4" autoPlay="" playsInline="" muted="" loop=""></video>
 
  <div className="cta">
  <div className="cta__content">
  <a href="https://www.pangeasocial.com/" target="_blank" rel="noopener noreferrer">
  <span>Learn More</span> 
  <span>
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">  <path fill-rule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clip-rule="evenodd"></path></svg> 
  </span>
  </a>
  </div>
  </div>
  <div className="section__content">
  
   
  
  <p>
  A <span>Safer Social Media</span> platform powered by <span>Socratic Dialogue AI</span> and <span>micro payment rewards</span>.
  </p>
  </div>
  </section>
  <section>
  <video src="/static/videos/Gxt0pt4FpYo.mp4" autoPlay="" playsInline="" muted="" loop=""></video>
  
  
  
  <div className="cta " style={{ display: 'none' }}>
    <div className="cta__content">
      <a id="button1" href="https://www.pangeasocial.com/" target="_blank" rel="noopener noreferrer">
        <span>Learn More</span>
        <span>
        
    <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">  <title>X</title><path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"></path></svg> 
        </span>
      </a>
    </div>
  </div>
  <div className="section__content">
    <p>
       Get <span>Ad-Free</span> AI powered insights &amp; metrics for your content and comments using our <span>AI enhanced writing editor &amp; social media platform.</span> Generate a true ROI for your time, higher quality content, and higher satisfaction <span>for you and your community. </span>
    </p>
  </div>
  
  </section> 
  <section>
    <video src="/static/videos/183147-low.mp4" autoPlay="" playsInline="" muted="" loop=""></video>
    <div className="cta">
      <div className="cta__content">
        <a id="button2" href="https://www.pangeasocial.com/" target="_blank" rel="noopener noreferrer">
        <span>Follow Us</span>
          <span>
            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>X</title><path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"></path></svg>
          </span>
        </a>
      </div>
    </div>
    <div className="section__content">
      <h2><span>Become a</span><span>Pangea Social Founding Member</span></h2>
    </div>
  
  <div className="cta">
    <div className="cta__content">
    <a id="button3" href="https://pangeasocial.com/" target="_blank" rel="noopener noreferrer">
    <span>Learn More</span> 
    <span>
      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">  <path fill-rule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clip-rule="evenodd"></path></svg> 
    </span>
    </a>
    </div>
    </div>
  </section>
  <section> 
    
    <h2>This is just 
  <br/>the beginning...</h2>
    <div className="cta">
    <div className="cta__content">
    <a id="button4" href="https://www.pangeasocial.com/" target="_blank" rel="noopener noreferrer">
    <span>Learn more</span> 
    <span>
      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">  <path fill-rule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clip-rule="evenodd"></path></svg> 
    </span>
    </a>
    </div>
    </div>
  </section> 
  <script src="/static/script.js"> 
    </script>
  
  
  
  </div></>);
}
// Add the following style tag inside your component or in your CSS file
const styles = `
  @keyframes fadeInFromBlack {
    from {
      background-color: black;
      opacity: 0;
    }
    to {
      background-color: transparent;
      opacity: 1;
    }
  }

  .page-dark {
    animation: fadeInFromBlack 5s ease forwards;
  }
`;


const LandingPage = ({ data }) => {
  // Function to access values using the key names from the data object
  const getValue = (key) => data[key];

  // Function to safely parse and render HTML content
  const renderHTML = (htmlContent) => parse(htmlContent);
  console.log(data)

  return ( <> 
    <style>{styles}</style>
      <div className={getValue("body_class")} translate="no">
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css"
        />

        <link rel="stylesheet" href="/static/custom.css" />

        <div className="support">{getValue("support_text")}</div>

        <nav>
          <img
            src={getValue("nav_image_src")}
            alt={getValue("nav_image_alt")}
          />
          <a
            href={getValue("nav_link_href")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getValue("nav_link_text")}
          </a>
        </nav>

        <section
          style={{
            backgroundImage: `url(${getValue("background_image_url")})`,
          }}
        >
          <h1>
            {getValue("section1_title_part1")}{" "}
            {getValue("section1_title_part2")}
          </h1>
          <a href={getValue("cta1_link_href")} className="cta-button">
            {getValue("cta1_text")}
          </a>
        </section>

        <section>
          <p>{renderHTML(getValue("section2_description"))}</p>
        </section>

        <section>
          <video
            src={getValue("video_src")}
            autoPlay
            playsInline
            muted
            loop
          ></video>
        </section>

        <section>
          <div dangerouslySetInnerHTML={{ __html: getValue("svg1_content") }} />
          <div dangerouslySetInnerHTML={{ __html: getValue("svg2_content") }} />
          <div dangerouslySetInnerHTML={{ __html: getValue("svg3_content") }} />
        </section>

        <section>
          {renderHTML(getValue("section3_title_part1"))}
          {renderHTML(getValue("section4_title_part1"))}
        </section>

        {/* Footer would be included here as a separate React component */}


  <script src="/static/script.js"> 
    </script>
    
      </div> </>
  );
};

// PropTypes for type checking the props
LandingPage.propTypes = {
  data: PropTypes.object.isRequired
};


export default LandingPage;